<template>

  <el-dialog
      title="新增"
      v-model="classInfo"
      width="30%"
      center append-to-body @close='closeDialog'>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
      <el-form-item label="分类名称" prop="name" :rules="[
      { required: true, message: '请输入名称', trigger: 'blur' },
    ]"
      >
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="分类状态" prop="status" :rules="[
      { required: true, message: '请选择状态', trigger: 'blur' },
    ]"
      >
        <el-radio-group  v-model="ruleForm.status">
          <el-radio :label="1"  border>开启</el-radio>
          <el-radio :label="0" border>禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
      <div class="ucForm-group-ad">
        <div class="ucForm-group ucBtn-bg">
          <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit('ruleForm')"><i class="iImg"></i>确定</button>
          <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog"><i
              class="iImg"></i>返回</button>
        </div>
      </div>
      <div class="clear"></div>
  </el-dialog>

</template>

<script>
export default {
  created () {
    this.classInfo = true;
    this.clearinfo();
  },

  data(){
    let self=this;
    return{
      ruleForm:{
        id:'',
        status:'',
        name:'',
      },
    }
    },

  methods: {

    commit:function(ruleForm){
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          var self = this;
          var data = {};
          data.cmd = "insertClassIficatioInfo";
          data.id=this.ruleForm.id;
          data.name=this.ruleForm.name;
          data.status=this.ruleForm.status;
          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){

            self.$message("新增成功");
            self.closeDialog();
          }, function(data) {
            self.$message(data.reason);
          }, self);
        }
        else {
          console.log('error submit!!');
          return false;
        }
      })

    },
    closeDialog: function(){
      this.members = false;
      this.clearinfo();
      this.$emit('closeClass'); //通知父组件改变。
    },
    clearinfo:function(){
      this.description = "";
    },


  },



  mounted(){

  },
  components: {

  }
}
</script>

<style scoped>

</style>