<template>
   <el-form ref="searchForm"   label-width="240" :inline="true">
    <el-form-item ><el-button   @click="addition()">新增</el-button></el-form-item>
    <el-form-item label="综合查询:" prop="name"><el-input  v-model="tableDataName" placeholder="请输入名称" style="width:240px" clearable></el-input></el-form-item>
    <el-form-item><el-button type="primary" @click="queryMembers()" icon="el-icon-search" >搜索</el-button></el-form-item>
    <el-form-item><el-button type="danger" @click="reset()" plain>重置</el-button></el-form-item>
  </el-form>
  <el-table :data="dataList" border  ref="multipleTable">
    <el-table-column prop="id" label="ID" ></el-table-column>
    <el-table-column prop="name" label="分类名称" ></el-table-column>
    <el-table-column prop="status" label="分类状态"  :formatter="forstusta">
      <template v-slot="scope">
        <el-button size="mini" :type="scope.row.status=='0'?'danger':'success'" @click="handleIsDisplay(scope.$index, scope.row)">
          {{scope.row.status=='0'?'禁用':'开启'}}
        </el-button>
      </template>
    </el-table-column>

    <el-table-column fixed="right" label="操作" width="200" >
      <template v-slot="scope">
        <el-button  icon="el-icon-edit"    @click="Modify(scope.row)" type="success" size="small">编辑</el-button>
        <el-button  icon="el-icon-delete"   @click="Deleteinfo(scope.row)" type="danger" size="small">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-config-provider :locale="locale">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total,sizes,prev,pager,next,jmper"
        :total="total">
    </el-pagination>
  </el-config-provider>
    <um_class_list @closeClass="closeClass"  v-if="classDialogVisible"></um_class_list>
  <um_class_add @closeClass="closeClassAdd"  v-if="classAddDialogVisible"></um_class_add>
</template>
<script>

import  um_class_list from './um_class_info'
import  um_class_add from './um_class_add'

//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {

  name: 'ZhProvider',

  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
  /*启动后触发该方法*/
  created() {
    this.handleClickQuery();
  },
  /*方法主体*/
  methods: {
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchClassIfication";
	  req.name=this.tableDataName;
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
        this.dataList=res.datas.items;
        this.total=res.datas.totalCount;
      }, null, this);
    },
    //条件查询的方法
    queryMembers:function(){
      this.dataList = [];
      var req = {};
      req.cmd = "searchClassIfication";
      req.name=this.tableDataName;
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.dataList=res.datas.items;
        this.total=res.datas.totalCount;
      }, null, this);
    },
    //重置查询条件
    reset:function(){
      this.tableDataName='';
      this.queryMembers();
    },
    /*删除的方法*/
    Deleteinfo:function(item){
      this.$confirm('是否确认删除。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updeteclass(item);
      }).catch(() => {});
    },
    updeteclass:function(item){
      var self = this;
      var data = {};
      data.cmd = "updateClassIficatio";
      data.id=item.id,
      data.is_del=1,
      this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
        self.$message("删除成功");
        this.handleClickQuery();
      }, function(data) {
      }, self);
    },
    Modify: function(item) {

      this.id = item.id+"";
      this.status = item.status+"";
      this.name = item.name+"";

      this.classDialogVisible = true;
    },
    closeClass:function(){
      this.id = '';
      this.mod = '';
      this.classDialogVisible = false;
      this.handleClickQuery();
    },
    addition:function(){
      this.id = '';
      this.mod = '';
      this.classAddDialogVisible = true;
    },
    closeClassAdd:function(){
      this.classAddDialogVisible = false;
      this.handleClickQuery();
    },

    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据

    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页
    },

    /*根据值返回汉字*/
    forstusta:function(row,colum){

      const state={
        0:'禁用',
        1:'开启',
      }
      return  state[row.status];
    },
    handleIsDisplay:function($index,row){
      var req = {};
      req.cmd = "updateClassInfoStatus";
      req.id=row.id;
      if(row.status == 1){
        req.status=0;
      }
      else if(row.status == 0){
        req.status=1;
      }
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.handleClickQuery();
      }, null, this);
    },
  },
  data() {
    let self = this;
    return{
      dataList:'',
      tableDataName:'',
      classDialogVisible: false,
      classAddDialogVisible:false,
      total:0,          //总页数
      pagesize:10,        //每页数量
      currentPage:1,    //初始页
    }
  },

  components: {
    um_class_list,
    um_class_add,
    [ElConfigProvider.name]: ElConfigProvider,

  },




}
</script>
<style>

</style>